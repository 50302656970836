import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    connect() {
        $(".datepicker").datepicker({
            defaultDate:0,
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd',
            onClose: function(selectedDate) {
                $( ".datepicker").datepicker('option', "maxDate", addDays($(this).datepicker('getDate'), 365) )
                    .datepicker( "option", "minDate", selectedDate );
            }
        });
    }
}


function addDays(theDate, days) {
    return new Date(theDate.getTime() + days*24*60*60*1000);
}
