import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    connect() {
        $('.multiselect').multiselect({
            multiple: true,
            includeSelectAllOption: true,
            allSelectedText: 'Todo',
            selectAllText: 'Seleccione Todo!',
            nonSelectedText: 'Seleccione',
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            filterPlaceholder: 'Filtrar',
            //maxHeight: 400,
            numberDisplayed: 1,
            buttonWidth: '100%',
            templates:{
                button:
                    '<button type="button" class="btn btn-gray multiselect dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ' +
                    'data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="mutliselect_tooltip"></button>',
                ul: '<ul class="multiselect-container dropdown-menu"></ul>',
                filter: '<li class="multiselect-item filter"><div class="input-group mb-2 mt-2"><span class="input-group-text">' +
                    '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-md" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
                    '<path stroke="none" d="M0 0h24v24H0z"></path><circle cx="10" cy="10" r="7"></circle><line x1="21" y1="21" x2="15" y2="15"></line>' +
                    '</svg></span><input type="text" class="form-control" placeholder="username"></div></li>',
                filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" typeclass="input-group mb-2 mt-3=" button"><i class="fa fa-times"></i></button></span>',
                li: '<li><a href="javascript:void(0);"><label></label></a></li>',
                divider: '<li class="multiselect-item divider"></li>',
                liGroup: '<li class="multiselect-item group"><label class="multiselect-group"></label></li>'
            }
        })
    }
}
