import {Controller} from "stimulus"
import $ from 'jquery';
// import scrollUp from '../../assets/javascripts/plantilla_home/jquery.scrollUp'

export default class extends Controller {


    connect() {
        let context = this;
        this.scrollUp = this.element;

        $(window).on('scroll', function () {
            let scrolled = $(window).scrollTop();

            if (scrolled > 300) $(context.scrollUp).addClass('active');
            if (scrolled < 300) $(context.scrollUp).removeClass('active');
        });

        $(this.scrollUp).on('click', function () {
            $("html, body").animate({
                scrollTop: "0"
            }, 1200);
        });

        setTimeout(()=>{
            $("html, body").removeAttr('style');
        },1000)
    }

}