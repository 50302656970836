import {Controller} from "stimulus"
import $ from 'jquery';
import {createPopper} from '@popperjs/core'

let placement = 'auto'
const popperInstance2 = (obj,text) => new Promise((resolve, reject) =>{
    resolve( createPopper(obj, text, {
        placement: placement,
        strategy: "absolute",
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [4, 4],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    padding: 10,
                },
            },
            {
                name: 'arrow',
                options: {
                    padding: 5, // 5px from the edges of the popper
                },
            },
        ],
    }))
});

function show(obj, instance) {
    obj.setAttribute('data-show', '');
    instance.update();
}

function hide(obj) {
    obj.removeAttribute('data-show');
}

export default class extends Controller {

    connect(){
        if(this.data.get('placement') != null)
            placement = this.data.get('placement')
    }

    tooltip(event){
        let element = $('#'+event.target.dataset.tooltip)[0];

        popperInstance2(event.target,element)
            .then( result => {
                if(event.type === 'touchstart'){
                    show(element, result);
                }
                if(event.type === 'touchend'){
                    hide(element);
                }
                if(event.type === 'mouseenter'){
                    show(element, result);
                }
                if(event.type === 'mouseleave'){
                    hide(element);
                }

            });

    }

}
