import { Controller } from "stimulus"
import $ from 'jquery';
require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
    }

    updateSrc(e){
        e.preventDefault();
        let url = this.data.get('url');
        $('#gatewaymini').attr('src', url);
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: this.data.get('mark_redsys_transaction_used'),
            data: {id: this.data.get('id')},
            success: function () {
                // jQuery.blockUI( {
                //     message: null,
                //     css: {
                //         border: 'none',
                //         padding: '15px',
                //         backgroundColor: '#000',
                //         '-webkit-border-radius': '10px',
                //         '-moz-border-radius': '10px',
                //         opacity: .5,
                //         color: '#fff'}
                // });
                // setTimeout(jQuery.unblockUI, 5000);
                jQuery('#confirm_form').css('display','none');
                jQuery('#request_data_ok_message').css('display','none');
                jQuery('#payment_form').css('display','block');
            },
            error: function () {
                // alert("Error");
            }
        });
    };
}