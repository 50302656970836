import {Controller} from "stimulus"
import $ from 'jquery';
// import scrollUp from '../../assets/javascripts/plantilla_home/jquery.scrollUp'

export default class extends Controller {
    connect() {

    }

    showPage(e) {
        window.location.href = `${location.origin}/certificado/${e.target.dataset.page}#${e.target.dataset.goTo}`;
    }
}