<template>
  <div class="row">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :is-full-page="true">
      </loading>
    </div>
    <div id="currency-list">
      <div class="card-header">
        <div class="col">
          <h3 class="card-title">{{results.table_name}}</h3>
        </div>
        <div class="col">
          <div class="action-btn ml-auto mr-3">
            <div class="dropdown export-btns" v-if="results.infos">
              <button class="btn btn-secondary  btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Exportar resultados de búsqueda">
                <i class="fas fa-download"></i>&nbsp;Exportar
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a :href="results.urls[0]['export']+'&format=pdf'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .pdf">
                  <i class="fas fa-file-pdf export-icon"></i>&nbsp; Exportar como PDF
                </a>
                <a :href="results.urls[0]['export']+'&format=xlsx'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .xlsx">
                  <i class="fas fa-file-excel"></i>&nbsp; Exportar como EXCEL
                </a>
                <a  :href="results.urls[0]['export']+'&format=csv'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .csv">
                  <i class="fas fa-file-csv"></i>&nbsp; Exportar como CSV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Cabecera -->
      <div class="card-body border-bottom py-3">
        <!--Listado de errores-->
        <div class="alert alert-danger alert-dismissible fade show" role="alert" v-show="results.errors.length > 0">
          <ul class="bs-callout-danger">
            <li v-for="error in results.errors">{{ error }}</li>
          </ul>
          <button type="button" class="close" @click="results.errors = []">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form @submit.prevent class="mb-1 px-3 py-2">
          <div class="d-flex">
            <div class="text-muted">
              Mostrando
              <div class="mx-sm-2 d-inline-block">
                <select v-model="results.count_by_page" @change="search('results')" name="count_by_page"
                        class="form-select count_by_page" style="min-width: 5rem !important;">
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              elementos
            </div>
            <div class="ml-auto text-muted">
              <div class="ml-2 d-inline-block search-act">
                <input placeholder="Buscar..." v-model="results.search" @keyup.enter="search('results')"
                       class="form-control" type="text" name="search"
                       data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="search_tooltip"/>
                <span id='search_tooltip' role='tooltip' class='text-tooltip' style='--t: #224646;'>
                  <span class='helper-text'>Presione la tecla 'ENTER' para buscar !!!</span>
                  <span id='arrow' data-popper-arrow></span>
                </span>
              </div>
            </div>
          </div>
        </form>

        <form @submit.prevent class="tablepager" v-if="showTablePager">
          <div class="card-header d-flex align-items-center">
            <div class="row w-100">
              <div class="col-sm col-xs-12">
                Página {{ results['page'] }} de {{ total_pages() }} (Total de elementos: {{ results.total_count }})
              </div>
              <div class="col-sm col-xs-12">
                <div class="pagination m-0">
                  <div class="ml-sm-auto col-xs-4 pl-0">
                    <span class="btn btn-ghost-secondary span-padding-left" v-bind:class="{ disabled: results['page'] <= 1 }"
                          title="Primera página" :disabled="results['page'] <= 1"
                          @click="pagerArrows('first', results)">
                      <i class="fas fa-step-backward"></i>
                    </span>
                    <span class="btn btn-ghost-secondary span-padding-left" v-bind:class="{ disabled: results['page'] <= 1 }"
                          title="Página anterior" :disabled="results['page'] <= 1"
                          @click="pagerArrows('prev', results)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                  </div>
                  <input type="text" class="form-control-rounded col-xs-1 input-table-header-page" v-model="results.page"
                         title="Página actual" @keyup.enter="search" @input="resultsPage">
                  <div class="col-xs-4 px-0 input-group-btn">
                    <span class="btn btn-ghost-secondary span-padding-right" v-bind:class="{ disabled: results['page'] >= total_pages() }"
                          title="Página siguiente" :disabled=" results['page'] >= total_pages()"
                          @click="pagerArrows('next', results)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                    <span class="btn btn-ghost-secondary span-padding-right" v-bind:class="{ disabled: results['page'] >= total_pages() }"
                          title="Última página" :disabled=" results['page'] >= total_pages()"
                          @click="pagerArrows('last', results)">
                      <i class="fas fa-step-forward"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!--Tabla de listado de Monedas-->
        <div class="table-responsive" data-controller="sweetalert tooltip notice">
          <table class="table card-table text-nowrap table-striped table-hover">
            <thead class="thead-light">
            <tr>
              <th v-bind:class='{"money": typeof results.class !== "undefined" && results.class[index] === "money" }' v-for="(header, index) in results.headers"> {{header}}</th>
              <th class="text-center mr-5 pr-5">ACCIONES</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="results.infos.length == 0">
              <td :colspan="results.headers.length + 3 " >
                <div class="alert alert-primary text-center col-12" style="min-width: 23.5625rem !important;" role="alert">
                  <p class="text-nowrap" style="color: #7b7b7c;">No existen elementos !!!</p>
                </div>
              </td>
            </tr>
            <template v-else v-for="(obj, index) in results.infos" v-if="doFor(index, results.count_by_page)">
              <tr v-if="index === 0"><td :colspan="results.headers.length + 3 " class="group"><span v-html="cleanMessage(obj.entity)"></span></td></tr>
              <tr v-if="index > 0 && typeof results.infos[index-1].entity !== 'undefined' && results.infos[index-1].entity !== obj.entity">
                <td :colspan="results.headers.length + 3 " class="group">
                  <span v-html="cleanMessage(obj.entity)"></span>
                </td>
              </tr>
              <tr :id="obj.id" :key="obj.id">
                <td v-bind:class='{"show-details": obj.entity_details, "money": typeof results.class !== "undefined" && results.class[index] === "money" }' @click="obj.entity_details ? showDetails(obj) : '' " v-for="(row, index) in results.rows">
                  <span v-if="row !== 'auditor'" v-html="cleanMessage(obj[row])"></span>
                  <span v-if="row === 'auditor' && obj[row] === true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75
                      0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </span>
                  <span v-if="row === 'auditor' && obj[row] === false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-ban" viewBox="0 0 16 16">
                      <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874
                      9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"/>
                    </svg>
                  </span>
                </td>
                <!--<td class="show-details" @click="showDetails(obj)">{{ obj.sequence }}</td>-->
                <td>
                  <div class="btn-list flex-nowrap center-content">
                    <a :href="obj.view" v-bind:class="{'disabled': !obj.no_deleted }" v-show="obj.view"><i>
                      <span aria-describedby="tooltip" class="tooltip-button " data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="show_tooltip">
                        <i class="icon-table-svg hybrid-text" style="--c:#85c1e9;--h:#2e86c1 ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                            <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5"></path>
                            <circle cx="16.5" cy="17.5" r="2.5"></circle>
                            <line x1="18.5" y1="19.5" x2="21" y2="22"></line>
                          </svg>
                        </i>
                      </span>
                      <span id='show_tooltip' role='tooltip' class='text-tooltip' style='--t: #2e86c1;'>
                        <span class='helper-text'>Ver</span>
                        <span id='arrow' data-popper-arrow></span>
                      </span>
                    </i></a>
                    <a :href="obj.edit" class="" v-bind:class="{'disabled': !obj.no_deleted }" title="Editar" v-show="obj.edit">
                      <i>
                        <span aria-describedby="tooltip" class="tooltip-button " data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="edit_tooltip">
                          <i class="icon-table-svg hybrid-text" style="--c: #e59866; --h: #ca6f1e;" title="">
                            <svg data-v-417de41e="" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path data-v-417de41e="" stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path data-v-417de41e="" d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                              <path data-v-417de41e="" d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                              <line data-v-417de41e="" x1="16" y1="5" x2="19" y2="8"></line>
                            </svg>
                          </i>
                        </span>
                        <span id='edit_tooltip' role='tooltip' class='text-tooltip' style='--t: #e59866;'>
                          <span class='helper-text'>Editar</span>
                          <span id='arrow' data-popper-arrow></span>
                        </span>
                      </i>
                    </a>
                    <span class="action-btns" v-for="(accion, index) in obj.get_actions" v-if="obj.get_actions" >
                      <div :id="accion[3]">
                        <a v-if="!accion[4]" :href="accion[1]" class="mr-1" v-show="accion[0]">
                          <span aria-describedby='tooltip' class='tooltip-button'
                                data-action='mouseenter->tooltip#tooltip
                                  mouseleave->tooltip#tooltip
                                  touchstart->tooltip#tooltip
                                  touchend->tooltip#tooltip'
                                :data-tooltip='accion[2][1]'>
                            <i class='icon-table-svg hybrid-text' :style='accion[2][3]' v-html="accion[2][0]"></i>
                          </span>
                          <span :id='accion[2][1]' role='tooltip' class='text-tooltip' :style='accion[2][4]'>
                            <span class='helper-text'>{{accion[2][2]}}</span>
                            <span id='arrow' data-popper-arrow></span>
                          </span>
                        </a>
                        <span v-else class="mr-1" v-show="accion[0]">
                          <span aria-describedby='tooltip' class='tooltip-button'
                                data-action='mouseenter->tooltip#tooltip
                                  mouseleave->tooltip#tooltip
                                  touchstart->tooltip#tooltip
                                  touchend->tooltip#tooltip'
                                :data-tooltip='accion[2][6]'>
                            <i class='icon-table-svg hybrid-text' :style='accion[2][7]' v-html="accion[2][0]"></i>
                          </span>
                          <span :id='accion[2][6]' role='tooltip' class='text-tooltip' :style="accion[2][5]">
                            <span class='helper-text'>{{accion[2][2]}}</span>
                            <span id='arrow' data-popper-arrow></span>
                          </span>
                        </span>
                      </div>
                    </span>
                    <span v-if="obj.no_deleted" @click="removeObj(obj, index, results.total_count)"  v-show="obj.delete">
                      <a href="#">
                        <i>
                          <span aria-describedby='tooltip'
                                class='tooltip-button '
                                data-action='mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip'
                                data-tooltip='delete_tooltip'>
                            <i class='hybrid-text icon-table-svg' style='--c: #f31f1f;--h:#f31f1f' title=''>
                              <svg xmlns="http://www.w3.org/2000/svg" class="" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 7h16" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                <path d="M10 12l4 4m0 -4l-4 4" />
                              </svg>
                            </i>
                          </span>
                          <span id='delete_tooltip' role='tooltip' class='text-tooltip' style='--t: #f31f1f;'>
                            <span class='helper-text'>Eliminar Usuario</span>
                            <span id='arrow' data-popper-arrow></span>
                          </span>
                        </i>
                      </a>
                    </span>
                    <span v-else v-show="obj.delete">
                      <i>
                        <span aria-describedby='tooltip'
                              class='tooltip-button '
                              data-action='mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip'
                              data-tooltip='delete_tooltip-forbidden'>
                          <i class='hybrid-text icon-table-svg' style='--c: #9a7f7d;--h:#9a7f7d' title=''>
                            <svg xmlns="http://www.w3.org/2000/svg" class="" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <path d="M4 7h16" />
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                              <path d="M10 12l4 4m0 -4l-4 4" />
                            </svg>
                          </i>
                        </span>
                        <span id='delete_tooltip-forbidden' role='tooltip' class='text-tooltip' style='--t: #9a7f7d;'>
                          <span class='helper-text'>Eliminar Usuario</span>
                          <span id='arrow' data-popper-arrow></span>
                        </span>
                      </i>
                    </span>
                  </div>
                </td>
              </tr>
<!--                               <tr class="details" :key="obj.id + '_details'" v-show="obj.show_details">-->
<!--                                   <td :colspan="results.headers.length + 3 " class="voucher-content">-->
<!--                                       <currency-details :r="obj" :history="obj.id" v-show="obj.currency_details" />-->
<!--                                   </td>-->
<!--                               </tr>-->
            </template>
            </tbody>
          </table>
        </div>
        <form @submit.prevent class="tablepager" v-if="showTablePager">
          <div class="card-header d-flex align-items-center">
            <div class="row w-100">
              <div class="col-sm col-xs-12">
                Página {{ results['page'] }} de {{ total_pages() }} (Total de elementos: {{ results.total_count }})
              </div>
              <div class="col-sm col-xs-12">
                <div class="pagination m-0">
                  <div class="ml-sm-auto col-xs-4 pl-0">
                    <span class="btn btn-ghost-secondary span-padding-left" v-bind:class="{ disabled: results['page'] <= 1 }"
                          title="Primera página" :disabled="results['page'] <= 1"
                          @click="pagerArrows('first', results)">
                      <i class="fas fa-step-backward"></i>
                    </span>
                    <span class="btn btn-ghost-secondary span-padding-left" v-bind:class="{ disabled: results['page'] <= 1 }"
                          title="Página anterior" :disabled="results['page'] <= 1"
                          @click="pagerArrows('prev', results)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                  </div>
                  <input type="text" class="form-control-rounded col-xs-1 input-table-header-page" v-model="results.page"
                         title="Página actual" @keyup.enter="search" @input="resultsPage">
                  <div class="col-xs-4 px-0 input-group-btn">
                    <span class="btn btn-ghost-secondary span-padding-right" v-bind:class="{ disabled: results['page'] >= total_pages() }"
                          title="Página siguiente" :disabled=" results['page'] >= total_pages()"
                          @click="pagerArrows('next', results)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                    <span class="btn btn-ghost-secondary span-padding-right" v-bind:class="{ disabled: results['page'] >= total_pages() }"
                          title="Última página" :disabled="results['page'] >= total_pages()"
                          @click="pagerArrows('last', results)">
                      <i class="fas fa-step-forward"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["results"],
  methods: {
    doFor(index, countByPage){
      return this.totalPages >= 1 && index < countByPage;
    },
    cleanMessage(message) {
      return this.$sanitize(message);
    },
    setTarget(obj){
      if(obj.length === 5 ){
        return (obj[0] === true ? "_blank" : "_self");
      }
    },
    errorMsm(response){
      let message = "";
      if (response.status === 0) {
        message = 'SIN CONEXIÓN: Verifique su conexión de red.';
      } else if(response.status == 400){
        message = 'EXISTEN PROBLEMAS EN EL REQUEST: Existen problemas con los datos enviados, intente nuevamente';
      } else if(response.status == 401){
        message = 'AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.';
      }else if (response.status == 403) {
        message == "AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.";
      } else if (response.status == 404) {
        message = 'PÁGINA NO ENCONTRADA: La página a la que está tratando de acceder nos se encontró, intente nuevamente';
      } else if (response.status == 500) {
        message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
      } else if (response.status == 503){
        message = "Service unavailable.";
      } else if (response.statusText === 'parsererror') {
        message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
      } else if (response.statusText === 'timeout') {
        message = 'TIME  OUT: Tiempo de espera superado, verifique su conexión de red.';
      } else if (response.statusText === 'abort') {
        message = 'REQUEST ABORTADO: La operación ha sido abortada';
      } else {
        message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
      }
      return message;
    },
    removeObj(obj, index, total) {
      const login_url = this.$props.results.urls[0]['login'];
      const url = obj.delete;
      this.$dialog.confirm(obj.confirmDialog, {
        okText:'Aceptar',
        cancelText:'Cerrar',
        loader: true,
        // And a dialog object will be passed to the then() callback
      })
          .then((dialog) => {
                        // Triggered when proceed button is clicked
                        this.isLoading = true;
                        this.$http.delete( url, {params: {id: obj.id, total: total }}).then(response => {

                                if(response.body.results != null && response.body.results.errors != null){
                                    this.$props.results.errors = [response.body.results.errors];
                                    this.isLoading = false;
                                    return
                                }
                                if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
                                    Turbolinks.clearCache();
                                    Turbolinks.visit(login_url, {"action":"replace"});
                                }
                                obj._destroy = true;
                                this.results.infos.splice(index, 1);
                                this.$delete;
                                this.isLoading = false;
                                this.$props.results.total_count = this.$props.results.total_count - 1 ;
                            }, response=> {
                                let message = this.errorMsm(response);
                                this.$props.results.errors = [message];
                                this.isLoading = false;
                            }
                        );
                        // dialog.loading(false) // stops the proceed button's loader
                        // dialog.loading(true) // starts the proceed button's loader again
                        dialog.close(); // stops the loader and close the dialog
                    })
          .catch(() => {
                        this.isLoading = false;
                        // Triggered when cancel button is clicked
                    });
    },
    search(){
      let vm = this;
      let force = force || false;
      if (vm.locked && !force) {
        return;
      }
      const login_url = this.$props.results.urls[0]['login'];
      const search_url = this.$props.results.urls[0]['search'];
      let data = $.extend(true, {}, vm.results);
      //EN GET los parámetros se pasan {params: {data: data}} en post {data: data}
      this.isLoading = true;
      this.$http.post(search_url, {data: data}).then(response => {
        if(vm.isBlank(response)) {
          this.$props.results.errors = ['No fue posible recuperar información desde el servidor'];
          this.isLoading = false;
          return;
        }
        if (response.body.results == null && response.body.substring(0, 10) === 'Turbolinks'){
          Turbolinks.clearCache();
          this.isLoading = false;
          Turbolinks.visit(login_url, {"action":"replace"});
        }
        this.$props['results'].page = response.body.results.page;
        this.$props['results'].count_by_page = response.body.results.count_by_page;
        this.$props['results'].total_count =  response.body.results.total_count;
        this.$emit("update-lists", response.body.results);
        this.isLoading = false;
      }, response=> {
        let message = this.errorMsm(response);
        this.$props.results.errors = [message];
        this.isLoading = false;
      });
    },
    showTablePager() {
      let vm = this;
      return vm.isPresent(vm.results) &&
          vm.isPresent(vm.total_pages) &&
          vm.total_pages > 1
          ;
    },
    pagerArrows(action, results) {
      let vm = this;
      switch(action) {
        case 'first':
          results.page = 1;
          break;
        case 'prev':
          results.page--;
          break;
        case 'next':
          results.page++;
          break;
        case 'last':
          results.page = vm.total_pages();
          break;
      }
      this.search();
    },
    resultsPage(e){
      let vm = this;
      let results = this.$props['results'];
      let value = e.target.value.replace(/[^0-9]*/g, '');
      if (!(value > 0)){
        results.page = '1';
        this.$nextTick(function () {
          $('#'+e.target.id).select();
        })
      }else if (vm.isPresent(vm.results) && vm.isPresent(vm.total_pages()) && value > parseInt(vm.total_pages())) {
        vm.results.page = vm.total_pages();
      } else if(value !== results['page']) {
        results.page = value;
      }
    },
    total_pages() {
      let vm = this;
      let results = this.$props['results'];
      let result = 1;
      if (vm.isPresent(results) && vm.isPresent(results.count_by_page) &&
          results.total_count > 0 && results.count_by_page > 0) {
        result = parseInt(results.total_count / results.count_by_page);
        if (results.total_count % results.count_by_page > 0) {
          result += 1;
        }
      }
      return result;
    },
    showDetails(obj) {
      obj.show_details = !obj.show_details;
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      componentKey: 0,
      totalPages: this.total_pages()
    }
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

[v-cloak] { display: none; }

#currency-list .action-btn { text-align: right; }

#currency-list { margin-top: 20px; }

#currency-list hr { margin-top: 0px; }

#currency-list .count_by_page { max-width: 80px; }

#currency-list .search-act { text-align: right; }

#currency-list .search-act input {
  max-width: 180px;
  display: inline-block;
}

#currency-list table tbody td {
  vertical-align: middle;
}

#currency-list .tablepager .input-group .input-group-btn .btn {
  padding: 4px 12px;
  font-size: 10px;
  cursor: pointer;
  margin-right: 0px;
}

#currency-list .tablepager .input-group-btn:first-child>.btn,  #currency-list .tablepager .input-group-btn:first-child>.btn-group {
  margin-right: -1px;
}

#currency-list .tablepager .input-group-addon:hover { background-color: #ddd; }

#currency-list .tablepager .input-group-addon:active { background-color: #ccc; }

#currency-list .tablepager {
  line-height: 24px;
}

#currency-list .tablepager .input-group {
  width: 200px;
  float: right;
}

#currency-list .tablepager input {
  text-align: center;
  height: 24px;
}

#currency-list .errors .bs-callout-danger {
  margin: 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #dFb5b4;
  background-color: #fcf2f2;
  list-style: none;
}

#currency-list .voucher-content {
  padding: 15px;
  background-color: white !important;
}

#currency-list .show-details {
  cursor: pointer;
}

#currency-list .voucher-content b {
  width: 150px;
  display: inline-block;
  text-align: right;
  margin-right: 15px;
}

#currency-list .dropdown-menu {
  min-width: 100px;
}

#currency-list .dropdown-menu-right.dropdown-menu:after {
  right: 5%;
}

#currency-list .recent-work {
  background-color: #ededed;
  font-weight: bold;
}

#currency-list td:first-child, #currency-list th:first-child  {
  padding-left: 15px;
}

#currency-list .recent-work td:first-child::before {
  content: '\f0da';
  font: normal normal normal 14px/1 FontAwesome;
  margin-left: -10px;
  font-size: 14px;
  padding-right: 5px;
}

#currency-list .results .daterange-dot:before {
  content: ':';
  float: left;
  margin-left: -13px;
  font-size: 20px;
  font-weight: bold;
}

#currency-list .details .details-data th {
  text-align: right;
  width: 122px;
  vertical-align: top;
  padding: 0 0 10px 0;
  margin: 0 0 10px;
}

#currency-list .details .details-data td {
  padding-left: 15px;
  padding-bottom: 10px;
}

#currency-list .export-btns {
  margin-bottom: 5px;
  margin-right: 5px;
}

#currency-list .export-btns .dropdown-menu { margin-top: 6px; }

#currency-list .export-btns .dropdown-menu li a {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
}

#currency-list .export-btns .dropdown-menu li a i { margin-right: 9px; font-size: 18px; }

#currency-list .export-btns .btn .caret { margin-left: 0; }

#currency-list .export-btns .dropdown-menu {
  left: auto;
}

#currency-list .action-btn .btn:last-child {
  margin-right: 0px;
}

#currency-list .action-btns .btn[disabled] {
  opacity: .3;
}

#currency-list .action-btns .btn {
  /*min-width: 25px;*/
}

#currency-list .form-group.action-btn {
  margin-top: 5px;
}

#currency-list .diff {
  text-decoration: line-through;
  color: #c70101;
}

#currency-list .details td span {
  line-height: inherit;
}

#currency-list .details-divider { margin-top: 20px; }

#currency-list .details-divider .badge {
  float: left;
  position: relative;
  display: inline-block;
  top: -11px;
  min-width: 23px;
  border-radius: 15px;
}

.centered-modal.in {
  display: flex !important;
}

.centered-modal .modal-dialog {
  margin: auto;
}

.btn_agency{
  color: #c70101;
}

.dropdown-menu>li>span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor:pointer;
}

.pagination-input{
  text-align: center;
  width: 10%;
}

.right-content{
  float: right;
}

.start-content {
  display: flex; /* Habilita el uso de Flexbox */
  justify-content: flex-start; /* Poner al principio horizontalmente */
}

.center-content {
  display: flex; /* Habilita el uso de Flexbox */
  justify-content: center; /* Centra horizontalmente */
}

.end-content {
  display: flex; /* Habilita el uso de Flexbox */
  justify-content: flex-end; /* Poner al final horizontalmente */
}

.input-paginator {
  max-height: 0.0222rem !important;
}

.group{
  height: 40px;
  background-color: #e3ebef !important;
  color: #0f477e;
}

@media (min-width: 768px) {
  #currency-list .results .daterange-dot {
    margin-top: 22px;
  }
}

@media (max-width: 576px) {
  .form-control-rounded {
    min-width: 3.125rem !important;
  }

  .span-padding-left {
    padding-left: 0 !important;
  }

  .span-padding-right {
    padding-right: 0 !important;
  }
}

</style>
