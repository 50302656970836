import {Controller} from "stimulus"

export default class extends Controller {
    static values = {
        id: String
    }
    connect() {
        document.getElementById(this.idValue).style.display = 'none'
    }
}
