import {Controller} from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import select2 from "select2"

// require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $('.c2_select').select2({
            theme: "bootstrap",
            language: "es"
        });

        $('.form-select').select2({
            theme: "bootstrap",
            language: "es"
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
    }
}
