import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    static targets = ["payTransactionAmount"];
    static values = {
        exchangeRateAllUrl: String,
        payTransBankTransfer: Boolean,
        payTransCompFee: Number
    }

    connect() {
        this.show_currency_amount();

        let context = this


        $('#pay_transaction_amount').on('keypress', function(e) {
            return context.numbersonly(this, e, true);
        });

        $('#days').on('keypress', function(e) {
            return context.numbersonly(this, e, true);
        });

        $('#pay_transaction_reservation_tax').on('keypress', function(e) {
            return context.numbersonly(this, e, true);
        });

        $('#collect_reserve_collect').on('click', function(e) {
            $('#cancel_tax').hide();
            $('#cancel_tax_1').hide();
        });

        $('#collect_reserve_reserve').on('click', function(e) {
            $('#cancel_tax').show();
            $('#cancel_tax_1').show();
        });
    }

    show_currency_amount() {
        let amount = $('#pay_transaction_amount')[0].value;
        let moneda = $('#currency')[0].value;

        $.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: `${this.exchangeRateAllUrlValue}`,
            data: {
                moneda: moneda,
                amount: amount,
                pay_trans_bank_transfer: this.payTransBankTransferValue,
                pay_trans_comp_fee: this.payTransCompFeeValue
            },
            success: function (response) {
                if (!response.default_currency) {
                    $("#cst_amount").hide();
                    $("#change_money").show();
                    $('#change_amount').val('RUB');
                    $('#change_amount').prop('disabled', true);
                    /*jQuery("#euro_amount").html('<b style="font-size: 15px;">₽' + euro_amount.toFixed(2) + '</b>' + '(1 ' + moneda + ' = ' + usd_exchange_rate.toFixed(4) + ' RUB ' + usd_exchange_rate_time + ') ');*/
                    $("#euro_amount").html(response.html_result);
                } else {
                    $("#euro_amount").html(response.html_result);
                    $("#cst_amount").html(response.html_result);
                    $("#cst_amount").show();
                    $("#change_money").hide();
                }
            },
            error: function (jqXHR, textStatus) {
                alert('Ha Ocurrido el siguiente error:' + jqXHR)
            }
        });
    }

    amount_empty() {
        if (this.payTransactionAmountTarget.value === '0.00') {
            this.payTransactionAmountTarget.value = '';
        }
    }

    pay_transaction_allow_client_cancel() {
        console.log('aki en pay_transaction_allow_client_cancel');
        console.log(this);

        if ($('#pay_transaction_allow_client_cancel').is(':checked')) {
            $('.reservation_tax').show();
        } else {
            $('.reservation_tax').hide();
        }
    }

    numbersonly(myfield, e, dec) {
        let key;
        let keychar;

        if (window.event)
            key = window.event.keyCode;
        else if (e)
            key = e.which;
        else
            return true;

        keychar = String.fromCharCode(key);

        // control keys
        if ((key == null) || (key == 0) || (key == 8) || (key == 9) || (key == 13) || (key == 27))
            return true;
        // numbers
        else if ((("0123456789").indexOf(keychar) > -1))
            return true;
        // decimal point jump
        else if (dec && (keychar == ".")) {
            myfield.form.elements[dec].focus();
            return false;
        } else
            return false;
    }
}